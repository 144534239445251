import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { forkJoin, Observable, Observer } from 'rxjs';
import { take } from 'rxjs/operators';
import { DocumentFileDto } from '../store/document-history/history.state';
import { EmployeeGenerateForm } from '../store/employees/employee-generate-form.model';
import { deEscapeHtml } from '../../shared/helpers/deEscapeHtml';
import { RoleEnum, UserModel } from '../../shared/models';
import { EmployeeList } from '../../shared/models';
import { List } from '../../shared/models';
import { DocumentOfList } from '../../shared/models';
import { AuthService } from '../../auth/auth.service';
import { EmployeeFilter } from '../store/filters/filters.models';
import { removeEmptyObjectValues } from '../../shared/helpers/other';
import { Store } from '@ngxs/store';
import { SetCurrentUser } from '../../store/app.actions';

export interface EmployeeFilterExtend extends EmployeeFilter {
  dateFrom: string;
  dateTo: string;
}

export interface DocumentTypes {
  checked: boolean;
  id: number;
  key: string;
  name: string;
  totalPages: number;
  isDisabled: boolean;
  isDynamicTest?: boolean;
  isTestFolder?: boolean;
}

export interface GenerateEmployeeResponse {
  id: string;
  uid?: string;
  password: string;
  autoLoginLink: string;
  loginPageLink: string;
}

export interface EmployeeDetailsModel {
  fullName?: string;
  statusId?: number;
  documents?: string[];
  assigns?: Array<{ id: number; name: string }>;
}

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private authService: AuthService,
    private store: Store,
    @Inject('API_URL') private apiUrl: string,
  ) {}

  getAll(filters?: EmployeeFilterExtend): Observable<any> {
    return new Observable((observer: Observer<List<EmployeeList[]>>) => {
      const newFilters = filters
        ? {
            ...filters,
            search: (deEscapeHtml(filters.search || '') as string).trim(),
            dateFrom: filters.dateFrom || '',
            dateTo: filters.dateTo || '',
            'statuses[]': filters.statuses,
            'skillCategories[]': filters.skillCategories,
            'sales[]': filters.sales,
            'caregiverStatuses[]': filters.caregiverStatuses,
            'assigned[]': filters.assigned,
            'aideType[]': filters.aideType,
          }
        : {};
      delete (<any>newFilters).statuses;
      delete (<any>newFilters).sales;
      delete (<any>newFilters).creators;
      delete (<any>newFilters).position;
      delete (<any>newFilters).caregiverStatuses;
      delete (<any>newFilters).assigned;
      delete (<any>newFilters).aideType;
      this.authService
        .getUser()
        .pipe(take(1))
        .subscribe((user: UserModel) => {
          this.store.dispatch(new SetCurrentUser(user));
          if (user?.roles[0]?.title !== RoleEnum.Employee) {
            this.http
              .get<EmployeeList[]>(`${this.apiUrl}/v2/employee`, {
                params: removeEmptyObjectValues(newFilters),
                observe: 'response',
              })
              .pipe(take(1))
              .subscribe({
                next: (success: HttpResponse<EmployeeList[]>) => {
                  observer.next({
                    data: success.body,
                    total: +success.headers.get('X-Items-Count'),
                  });
                  observer.complete();
                },
                error: (err: any) => {
                  observer.error(err);
                },
              });
          }
        });
    });
  }

  generateEmployee(employeeData: EmployeeGenerateForm): Observable<GenerateEmployeeResponse> {
    return this.http.post<GenerateEmployeeResponse>(`${this.apiUrl}/v2/employee/generate/new-ss`, { ...employeeData });
  }

  sendEmail(email: string, data: { uid: string; password: string }): Observable<any> {
    return this.notifyNewEmployee({ ...data, email });
  }

  sendMessage(phone: number, data: { uid: string; password: string }): Observable<any> {
    return this.notifyNewEmployee({ ...data, phone });
  }

  notifyNewEmployee(body: { uid; password; phone?; email? }): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/users/notify/new-account`, body);
  }

  getEmployeeAuthorizedRepresentative(search: string, page: number = 1) {
    return this.http.get<
      Array<{
        uid: string;
        fullName: string;
        complexName: string;
      }>
    >(`${this.apiUrl}/v2/employee/i9-authorized-representative`, {
      params: {
        search,
        page,
        perPage: 20,
      },
    });
  }

  getDocumentHistory(uid: string, role: string, filters?): Observable<List<DocumentOfList[]>> {
    let params: any = {};
    if (filters) {
      params = {
        ...filters,
        search: (filters.search || '').trim(),
      };
    }
    Object.keys(params || {}).forEach((key: string) => {
      if (Array.isArray(params[key]) && key !== 'xchangeSync') {
        params[key + '[]'] = [...params[key]];
        delete params[key];
      }
    });
    if (params.date) {
      params = {
        ...params,
        dateFrom: this.datePipe.transform(params.date.from, 'yyyy-MM-dd') || '',
        dateTo: this.datePipe.transform(params.date.to, 'yyyy-MM-dd') || '',
      };
      delete params.date;
    }
    const endpoint: string = role === RoleEnum.Employee ? `${this.apiUrl}/v2/profile/documents` : `${this.apiUrl}/v2/employee/documents/${uid}`;
    return new Observable((observer: Observer<List<DocumentOfList[]>>) => {
      this.http
        .get(endpoint, { params: removeEmptyObjectValues(params), observe: 'response' })
        .pipe(take(1))
        .subscribe({
          next: (success: HttpResponse<DocumentOfList[]>) => {
            observer.next({
              data: success.body,
              total: +success.headers.get('X-Items-Count'),
            });
            observer.complete();
          },
          error: (err: any) => {
            observer.error(err);
          },
        });
    });
  }

  getDocumentTypes(uid: string, withoutId: boolean): Observable<DocumentTypes[]> {
    if (!withoutId) {
      return this.http.get<DocumentTypes[]>(`${this.apiUrl}/employee/${uid}/document-types`);
    } else {
      return this.http.get<DocumentTypes[]>(`${this.apiUrl}/document-types`);
    }
  }

  createHistoryItem(uid: string, documentTypes: number[], allDocuments?: boolean): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/users/application/history`, {
      uid: deEscapeHtml(uid),
      documentTypes,
      allDocuments,
    });
  }

  makeStartedAO(uid: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/users/application/history/ao`, { uid });
  }

  deleteHistoryItem(uid: string, documentHistoryId: number): Observable<void> {
    const params = { uid, documentHistoryId: documentHistoryId.toString() };
    return this.http.delete<void>(`${this.apiUrl}/users/application/history`, { params });
  }

  downloadDocument(uid: string, role: string, singleFile?: 'archive' | 'pdf', historyId?: number): Observable<any> {
    let params: any = { uid, documentHistoryId: historyId };
    if (singleFile) {
      const downloadParam = singleFile === 'archive' ? { singleFile: '1' } : { oneFile: '1' };
      params = {
        ...params,
        uid: deEscapeHtml(params.uid),
        ...downloadParam,
      };
    }
    const endpoint: string =
      role === RoleEnum.Employee ? `${this.apiUrl}/employee/application/download-documents` : `${this.apiUrl}/users/application/download-documents`;

    return this.http.get<any>(endpoint, { params });
  }

  uploadDocument(fileData: DocumentFileDto): Observable<any> {
    const formData: FormData = new FormData();
    Object.keys(fileData || {}).forEach((key: string) => formData.append(key, fileData[key]));
    return this.http.post<any>(`${this.apiUrl}/users/application/upload-document`, formData);
  }

  historySendEmail(documentHistoryId: number, uid: string, allDocuments: boolean, smsMobileNumber?: string | number): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/users/application/send-signed`, {
      documentHistoryId,
      uid: deEscapeHtml(uid),
      smsMobileNumber: smsMobileNumber || '',
      allDocuments,
    });
  }

  sendByEmail(documentTypeIds: number[], uid: string, allDocuments: boolean, smsMobileNumber?: string | number): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/users/application/send-signed`, {
      documentTypeIds,
      uid: deEscapeHtml(uid),
      smsMobileNumber: smsMobileNumber || '',
      allDocuments,
    });
  }

  signDocument(documentHistoryId: number, sign: string, uid: string, payload?: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/users/application/signed`, {
      documentHistoryId,
      sign,
      uid: deEscapeHtml(uid),
      ...payload,
    });
  }

  hhaExchangeSync(userId: string, historyDocIds?: string[]): Observable<any> {
    if (historyDocIds) {
      return forkJoin(historyDocIds.map((id: string) => this.http.post(`${this.apiUrl}/users/${userId}/hhaexchange/history/${id}`, {})));
    } else {
      return this.http.post<any>(`${this.apiUrl}/users/${userId}/hhaexchange`, {});
    }
  }

  changeDocumentDate(uid: string, newDate: string, property: string = 'createdAt', documentHistoryId?: number): Observable<any> {
    const payload = documentHistoryId ? { uid, [property]: newDate, documentHistoryId } : { uid, [property]: newDate };
    return this.changeDate(payload);
  }

  changeDate(payload: { uid: string; documentHistoryId?: number; signedDate?: string; completedDate?: string; createdAt?: string }): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/users/application/change-dates`, payload);
  }

  setSpecialStatus(documentHistoryId: string | number, statusId: string | number): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/users/application/history/${documentHistoryId}/status/${statusId}`, {});
  }

  getUserList(search: string, params: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/v2/employee/assign/users`, { params: <any>{ search, forFilter: params } });
  }

  assignedUser(uuid: string[], assignIds: string[]): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/v2/employee/assign`, { assignIds, uids: uuid });
  }

  deleteAssignedUser(employeeId: string, userId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/v2/employee/${employeeId}/assign/${userId}`);
  }

  getEmployeeDetails(uid: string): Observable<EmployeeDetailsModel> {
    return this.http.get<EmployeeDetailsModel>(`${this.apiUrl}/v2/employee/${uid}/details`);
  }
}
